module.exports = [{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-material-ui-virtual-b2217cf706/0/cache/gatsby-plugin-material-ui-npm-3.0.1-0b0b0790ae-db7c8d7f11.zip/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-next-seo-virtual-6a0e033bbc/0/cache/gatsby-plugin-next-seo-npm-1.7.0-3b6b3699c4-08476c06fe.zip/node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"titleTemplate":"%s | CJT Lab","language":"en-DE","defaultOpenGraphImageWidth":1200,"defaultOpenGraphImageHeight":630,"openGraph":{"type":"website","locale":"en_DE","url":"https://jessen-trefzer.research-lab.org","site_name":"Claudia Jessen-Trefzer Lab","images":[{"url":"https://jessen-trefzer.research-lab.org/img/chemicals_compressed.jpg","alt":"Og Image Alt A"}]}},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-image-virtual-1934c670f5/0/cache/gatsby-plugin-image-npm-1.7.0-ac6e4561fe-c60b072bc7.zip/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-remark-images-virtual-ae3fec76a1/0/cache/gatsby-remark-images-npm-5.4.0-c4901d9854-1a38c85dba.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"linkImagesToOriginal":false,"showCaptions":true,"withWebp":{"quality":80},"quality":60,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-netlify-cms-virtual-73ed29f824/0/cache/gatsby-plugin-netlify-cms-npm-5.7.0-d67352d555-7e48a880e6.zip/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/builds/researchlab-org/cjt-website/src/cms/cms.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
