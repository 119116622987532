// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-integration-templates-article-page-js": () => import("./../../../src/integration/templates/articlePage.js" /* webpackChunkName: "component---src-integration-templates-article-page-js" */),
  "component---src-integration-templates-index-page-js": () => import("./../../../src/integration/templates/indexPage.js" /* webpackChunkName: "component---src-integration-templates-index-page-js" */),
  "component---src-integration-templates-lab-article-page-js": () => import("./../../../src/integration/templates/labArticlePage.js" /* webpackChunkName: "component---src-integration-templates-lab-article-page-js" */),
  "component---src-integration-templates-lab-overview-page-js": () => import("./../../../src/integration/templates/labOverviewPage.js" /* webpackChunkName: "component---src-integration-templates-lab-overview-page-js" */),
  "component---src-integration-templates-publications-page-js": () => import("./../../../src/integration/templates/publicationsPage.js" /* webpackChunkName: "component---src-integration-templates-publications-page-js" */),
  "component---src-integration-templates-research-article-page-js": () => import("./../../../src/integration/templates/researchArticlePage.js" /* webpackChunkName: "component---src-integration-templates-research-article-page-js" */),
  "component---src-integration-templates-research-overview-page-js": () => import("./../../../src/integration/templates/researchOverviewPage.js" /* webpackChunkName: "component---src-integration-templates-research-overview-page-js" */),
  "component---src-integration-templates-team-overview-page-js": () => import("./../../../src/integration/templates/teamOverviewPage.js" /* webpackChunkName: "component---src-integration-templates-team-overview-page-js" */),
  "component---src-integration-templates-team-page-js": () => import("./../../../src/integration/templates/teamPage.js" /* webpackChunkName: "component---src-integration-templates-team-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

